

import React, { Component } from 'react';

export default class EditWarrantPage extends Component {
    render() {
        //   const MyGreen = {
        //    color: 'green'
        //   };
        return (
            <div class="content-wrapper">

                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Edit Warrant</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    {/* <li class="breadcrumb-item"><a href=" ">Home</a></li>
              <li class="breadcrumb-item active">General Form</li> */}
                                    <a href='/warrants' class="btn btn-primary">Back</a>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-secondary">
                                    <div class="card-header">
                                        <h3 class="card-title">Form</h3>
                                    </div>
                                    <form>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Against Whom</label>
                                                        <input type="text" class="form-control" placeholder="Enter against whom" />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Station District</label>
                                                        <select class="form-control">
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Police Station</label>
                                                        <select class="form-control">
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Crime Number</label>
                                                        <input type="number" class="form-control" placeholder="Enter crime number" />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Sections</label>
                                                        <input type="text" class="form-control" placeholder="Enter sections" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Court Name</label>
                                                        <input type="text" class="form-control" placeholder="Enter court name " required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Court Case Number</label>
                                                        <input type="number" class="form-control" placeholder="Enter court case number" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Date of Receipt</label>
                                                        <input type="date" class="form-control" placeholder="Enter against whom" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Warranty Name</label>
                                                        <input type="text" class="form-control" placeholder="Enter warranty name" />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Warrant Date</label>
                                                        <input type="date" class="form-control" placeholder="Enter against whom" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Father Name</label>
                                                        <input type="number" class="form-control" placeholder="Enter father name" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Age</label>
                                                        <input type="number" class="form-control" placeholder="Enter age" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Address</label>
                                                        <input type="text" class="form-control" placeholder="Enter address" required />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>State</label>
                                                        <select class="form-control" required>
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>District</label>
                                                        <select class="form-control" required>
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Village</label>
                                                        <select class="form-control" required>
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Return to Court</label>
                                                        <input type="date" class="form-control" required />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>By Whom</label>
                                                        <select class="form-control" required>
                                                            <option hidden>Choose one</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                            <option>Demo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Execution States</label>
                                                        <input type="text" class="form-control" placeholder="Enter execution states" required />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="">Remark</label>
                                                        <input type="text" class="form-control" placeholder="Enter remark" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="card-footer">
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>



                            </div>

                        </div>

                    </div>
                </section>

            </div>
        )
    }
}