import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import WarrantPage from '../Component/WarrantPage';

export default function Warrant() {
  return (
    <div>
        <Header />
        <SideBar />
        <WarrantPage />
    </div>
  )
}
