import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import PoliceStationPage from '../Component/PoliceStationPage';

export default function PoliceStation() {
  return (
    <div>
        <Header />
        <SideBar />
        <PoliceStationPage />
    </div>
  )
}
