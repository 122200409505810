

import React, {Component} from 'react';

export default class EditUserPage extends Component {
    render(){
    //   const MyGreen = {
    //    color: 'green'
    //   };
        return (
            <div class="content-wrapper">
 
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Edit User</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              {/* <li class="breadcrumb-item"><a href=" ">Home</a></li>
              <li class="breadcrumb-item active">General Form</li> */}
              <a href='/users' class="btn btn-primary">Back</a>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-secondary">
              <div class="card-header">
                <h3 class="card-title">Form</h3>
              </div>
       
              <form>
                <div class="card-body">
                  <div class="form-group">
                    <label for="">Username</label>
                    <input type="text" class="form-control"  placeholder="Enter username"/>
                  </div>
                  <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" class="form-control"  placeholder="Enter email"/>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"/>
                  </div>
                  <div class="form-group">
                     <label>Role</label>
                        <select class="form-control">
                          <option hidden>Choose one</option>
                          <option>Super Admin</option>
                          <option>Admin</option>
                          <option>User</option>
                        </select>
                      </div>
                </div>
               

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Update</button>
                </div>
              </form>
            </div>
           

          
          </div>
        
        </div>
        
      </div>
    </section>
  
  </div>
        )
    }
}