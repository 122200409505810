

import React, { Component } from 'react';

export default class EditStatePage extends Component {
  render() {
    //   const MyGreen = {
    //    color: 'green'
    //   };
    return (
      <div class="content-wrapper">

        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Edit State</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  {/* <li class="breadcrumb-item"><a href=" ">Home</a></li>
              <li class="breadcrumb-item active">General Form</li> */}
                  <a href='/state' class="btn btn-primary">Back</a>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Form</h3>
                  </div>

                  <form>
                    <div class="card-body">
                      <div class="form-group">
                        <label for="">Name</label>
                        <input type="text" class="form-control" placeholder="Enter name" />
                      </div>
                      <div class="form-group">
                        <label>Status</label>
                        <select class="form-control">
                          <option hidden>Choose one</option>
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                
                    </div>


                    <div class="card-footer">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>



              </div>

            </div>

          </div>
        </section>

      </div>
    )
  }
}