import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import WarrantDetailPage from '../Component/WarrantDetailPage';

export default function WarrantDetail() {
  return (
    <div>
        <Header />
        <SideBar />
        <WarrantDetailPage />
    </div>
  )
}
