

import React, { Component } from 'react';

export default class WarrantDetailPage extends Component {
    render() {
        //   const MyGreen = {
        //    color: 'green'
        //   };
        return (
            <div class="content-wrapper">

                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1>Warrant Details</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    {/* <li class="breadcrumb-item"><a href=" ">Home</a></li>
              <li class="breadcrumb-item active">General Form</li> */}
                                    <a href='/warrants' class="btn btn-primary">Back</a>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                        <div class="col-md-6">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Details</h3>
                  </div>

                  
                    <div class="card-body">
                     <table  class="table table-bordered table-striped">
                      <tr>
                        <th>Against Whom</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Station District</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Police Station</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Crime Number</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Sections</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Court Name</th>
                        <td>Demo</td>
                      </tr>
                     </table>
                    
                
                    </div>                 
                </div>



              </div>

              <div class="col-md-6">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Details</h3>
                  </div>

                  
                    <div class="card-body">
                     <table  class="table table-bordered table-striped">
                   
                      <tr>
                        <th>Court Case Number</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Date of Receipt</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Warranty Name</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Warrant Date</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Father Name</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Age</th>
                        <td>Demo</td>
                      </tr>
                     </table>
                    
                
                    </div>                 
                </div>



              </div>

              <div class="col-md-6">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Details</h3>
                  </div>

                  
                    <div class="card-body">
                     <table  class="table table-bordered table-striped">
                     <tr>
                        <th>Address</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>State</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>District</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Village</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Return to Court</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>By Whom</th>
                        <td>Demo</td>
                      </tr>
                     </table>
                    
                
                    </div>                 
                </div>



              </div>

          
              <div class="col-md-6">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Details</h3>
                  </div>

                  
                    <div class="card-body">
                     <table  class="table table-bordered table-striped">
                     
                      <tr>
                        <th>Execution States</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Remark</th>
                        <td>Demo</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>Active</td>
                      </tr>
                     
                     </table>
                    
                
                    </div>                 
                </div>



              </div>

                        </div>

                    </div>
                </section>

            </div>
        )
    }
}