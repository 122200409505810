import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import DistrictPage from '../Component/DistrictPage';

export default function State() {
  return (
    <div>
        <Header />
        <SideBar />
        <DistrictPage />
    </div>
  )
}
