// Header.js
import React, {Component} from 'react';

export default class Header extends Component {
    render(){
      const Clock = {
        color:'black',
        marginTop: '6px'
    };
    const Clock_back = {
      backgroundColor:'black',
      color:'white'
    };
        return (
            <header>
  {/* <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__shake" src="/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60"/>
  </div> */}

  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href=" " role="button"><i class="fas fa-bars"></i></a>
      </li>
      {/* <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li> */}
      <li class="nav-item">
      <h5 style={Clock} class="font-weight-bold"><span class="date-text"></span> | <span style={Clock_back}
      class="badge badge-warning" id="ct"></span></h5>
      </li>
    
    </ul>

    <ul class="navbar-nav ml-auto">
    

      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href=" ">
          <i class="far fa-user"></i>
      
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">Menu</span>
          <div class="dropdown-divider"></div>
          <a href=" " class="dropdown-item">
            <i class="fas fa-user mr-2"></i>Profile
          </a>
          <div class="dropdown-divider"></div>
          <a href=" " class="dropdown-item">
            <i class="fas fa-sign-out-alt mr-2"></i>Logout
          </a>
       
        </div>
      </li>
    

     
    </ul>
  </nav>
            </header>
        )
    }
}