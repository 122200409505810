import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import AddWarrantPage from '../Component/AddWarrantPage';

export default function AddWarrant() {
  return (
    <div>
        <Header />
        <SideBar />
        <AddWarrantPage />
    </div>
  )
}
