import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import AddDistrictPage from '../Component/AddDistrictPage';

export default function AddDistrict() {
  return (
    <div>
        <Header />
        <SideBar />
        <AddDistrictPage />
    </div>
  )
}
