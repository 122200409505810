import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import User from './pages/User';
import Warrant from './pages/Warrant';
import PoliceStation from './pages/PoliceStation';
import State from './pages/State';
import District from './pages/District';
import Village from './pages/Village';
import AddUser from './pages/AddUser';
import AddPoliceStation from './pages/AddPoliceStation';
import AddState from './pages/AddState';
import AddDistrict from './pages/AddDistrict';
import AddVillage from './pages/AddVillage';
import AddWarrant from './pages/AddWarrant';
import WarrantDetail from './pages/WarrantDetail';
import EditUser from './pages/EditUser';
import EditPoliceStation from './pages/EditPoliceStation';
import EditState from './pages/EditState';
import EditDistrict from './pages/EditDistrict';
import EditVillage from './pages/EditVillage';
import EditWarrant from './pages/EditWarrant';

// import Login from './pages/Login';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "dashboard",
    element: <App/>,
  },
  {
    path: "users",
    element: <User/>,
  },
  {
    path: "police-stations",
    element: <PoliceStation/>,
  },
  {
    path: "warrants",
    element: <Warrant/>,
  },
  {
    path: "state",
    element: <State/>,
  },
  {
    path: "district",
    element: <District/>,
  },
  {
    path: "village",
    element: <Village/>,
  },
  {
    path: "add-user",
    element: <AddUser/>,
  },
  {
    path: "add-police-station",
    element: <AddPoliceStation/>,
  },
  {
    path: "add-state",
    element: <AddState/>,
  },
  {
    path: "add-district",
    element: <AddDistrict/>,
  },
  {
    path: "add-village",
    element: <AddVillage/>,
  },
  {
    path: "add-warrant",
    element: <AddWarrant/>,
  },
  {
    path: "warrant-detail",
    element: <WarrantDetail/>,
  },
  {
    path: "edit-user",
    element: <EditUser/>,
  },
  {
    path: "edit-police-station",
    element: <EditPoliceStation/>,
  },
  {
    path: "edit-state",
    element: <EditState/>,
  },
  {
    path: "edit-district",
    element: <EditDistrict/>,
  },
  {
    path: "edit-village",
    element: <EditVillage/>,
  },
  {
    path: "edit-warrant",
    element: <EditWarrant/>,
  },
]);


// Replace ReactDOM.render with createRoot
createRoot(document.getElementById('App')).render(
<RouterProvider router={router} />
);



