import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import EditPoliceStationPage from '../Component/EditPoliceStationPage';

export default function EditPoliceStation() {
  return (
    <div>
        <Header />
        <SideBar />
        <EditPoliceStationPage/>
    </div>
  )
}
