import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import EditDistrictPage from '../Component/EditDistrictPage';

export default function EditDistrict() {
  return (
    <div>
        <Header />
        <SideBar />
        <EditDistrictPage />
    </div>
  )
}
