import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import AddPoliceStationPage from '../Component/AddPoliceStationPage';

export default function AddPoliceStation() {
  return (
    <div>
        <Header />
        <SideBar />
        <AddPoliceStationPage />
    </div>
  )
}
