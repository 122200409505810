import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import EditWarrantPage from '../Component/EditWarrantPage';

export default function EditWarrant() {
  return (
    <div>
        <Header />
        <SideBar />
        <EditWarrantPage />
    </div>
  )
}
