// App.js

import React, { Component } from 'react';
import Header from './Component/Header';
import SideBar from './Component/SideBar';
import Content from './Component/Content';


class App extends Component {

  render() {
    return (
      <div>
        <Header />
        <SideBar />
        <Content />
      </div>
    );
  }
}

export default App;