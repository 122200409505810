import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import EditVillagePage from '../Component/EditVillagePage';

export default function EditVillage() {
  return (
    <div>
        <Header />
        <SideBar />
        <EditVillagePage />
    </div>
  )
}
