import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import AddUserPage from '../Component/AddUserPage';

export default function AddUser() {
  return (
    <div>
        <Header />
        <SideBar />
        <AddUserPage />
    </div>
  )
}
