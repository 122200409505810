// Content.js

import React, { Component } from 'react';

export default class Content extends Component {
  render() {
    // const MyFont = {
    //   fontSize: '20px'
    // };
    return (
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Dashboard</h1>
              </div>
              <div class="col-sm-6">
            
              </div>
            </div>
          </div>
        </div>


        <section class="content">
          <div class="container-fluid">

            <div class="row">
              <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                  <span class="info-box-icon bg-info"><i class="far fa-user"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Users</span>
                    <span class="info-box-number">5</span>
                  </div>

                </div>

              </div>

              <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                  <span class="info-box-icon bg-success"><i class="far fa-building"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Police Stations (Thana)</span>
                    <span class="info-box-number">10</span>
                  </div>

                </div>

              </div>

              <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                  <span class="info-box-icon bg-warning"><i class="far fa-file"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Warrants</span>
                    <span class="info-box-number">2</span>
                  </div>

                </div>

              </div>

              <div class="col-md-3 col-sm-6 col-12">
                <div class="info-box">
                  <span class="info-box-icon bg-danger"><i class="fa fa-globe"></i></span>

                  <div class="info-box-content">
                    <span class="info-box-text">Districts</span>
                    <span class="info-box-number">10</span>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </section>

      </div>
    )
  }
}