// SideBar.js

import React, {Component} from 'react';

export default class SideBar extends Component {
    render(){
        return (
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
  
    <a href="/dashboard" class="brand-link">
      <img src="/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style={{opacity: .8}}/>
      <span class="brand-text font-weight-light">Admin Panel</span>
    </a>

   
    <div class="sidebar">
     

  
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
         
         
          <li class="nav-item">
            <a href="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
              </p>
            </a>
          </li>
          
          <li class="nav-item">
            <a href="/users" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Users
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/police-stations" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
               Police Stations
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/warrants" class="nav-link">
              <i class="nav-icon fas fa-file"></i>
              <p>
                Warrants
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/state" class="nav-link">
              <i class="nav-icon fas fa-map-marker"></i>
              <p>
                State
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/district" class="nav-link">
              <i class="nav-icon fas fa-location-arrow"></i>
              <p>
                District
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/village" class="nav-link">
              <i class="nav-icon fas fa-paper-plane"></i>
              <p>
                Village
              </p>
            </a>
          </li>

           {/* <li class="nav-item menu">
            <a href=" " class="nav-link ">
              <i class="nav-icon fas fa-list"></i>
              <p>
                Content
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="state" class="nav-link ">
                  <i class="far fa-circle nav-icon"></i>
                  <p>State</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="district" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>District</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="village" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Village</p>
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </nav>
    </div>
  </aside>
        )
    }
}