import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import VillagePage from '../Component/VillagePage';

export default function State() {
  return (
    <div>
        <Header />
        <SideBar />
        <VillagePage />
    </div>
  )
}
