

import React, { Component } from 'react';

export default class WarrantPage extends Component {
  render() {
    const EditBtn = {
      marginLeft: '5px'
    };
    return (
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Warrants</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  {/* <li class="breadcrumb-item"><a href="/">Home</a></li>
                      <li class="breadcrumb-item active">Users</li> */}
                  <a href='/add-warrant' class="btn btn-primary">Add New</a>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">


                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Table</h3>
                  </div>
                  <div class="card-body">
                    <table id="example1" class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Against Whom</th>
                          <th>Police Station</th>
                          <th>Station District</th>
                          <th>Crime No</th>
                          <th>Court Name</th>
                          <th>Warranty Name</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>demo</td>
                          <td>
                            <div class="btn-group">
                              <a href="/warrant-detail" class="btn btn-success btn-sm">View</a>
                              <a style={EditBtn} href="/edit-warrant" class="btn btn-secondary btn-sm">Edit</a>
                            </div>
                          </td>
                        </tr>


                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}