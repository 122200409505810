import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import EditUserPage from '../Component/EditUserPage';

export default function EditUser() {
  return (
    <div>
        <Header />
        <SideBar />
        <EditUserPage />
    </div>
  )
}
