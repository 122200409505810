import React from 'react'
import Header from '../Component/Header';
import SideBar from '../Component/SideBar';
import AddVillagePage from '../Component/AddVillagePage';

export default function AddVillage() {
  return (
    <div>
        <Header />
        <SideBar />
        <AddVillagePage />
    </div>
  )
}
